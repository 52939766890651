// import { CurrentAuthenticatedGuest } from 'services/GuestAuth'
// import { logger } from 'services/CloudLogger'
import {REQUEST_TIMEOUT_MSEC} from '../Constants'
import { getTokenHash } from '../services';
/**
 * This function is called on everey request response.
 * Returns the reponse data.
 * In Case of an error, throws an error with the error status.
 */
async function parseResponse(res) {
    let data = {};
    // If a body response exists, parse anx extract the possible properties
    if (res.ok) {
        data = res.status !== 204 ? await res.json() : {};
    } else {
        // logger.log("Request Error: ", res.status);
        if (res.status === "401") {
            //window.location.reload(false);
            // TODO: handle it
        }
        throw new Error(res.status);
    }
    return data;
}

function fetchWithTimeout(url, options, timeout) {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject("REQUEST_TIMEOUT"), timeout)
        )
    ]);
}

 export function simple_request(url, options = {}, timeout = REQUEST_TIMEOUT_MSEC, isFile=false) {
    const {
        headers,
        query = null,
        method = 'GET',
        body,
        ...extraOpts
    } = options;

    // Compose the request configuration object
    const reqOptions = {
        method,
        headers,
        body,
        ...extraOpts,
    };

    // If a body object is passed, automatically stringify it.
    if (reqOptions.body && !isFile) {
        reqOptions.body = JSON.stringify(reqOptions.body);
    }

    let queryString = '';
    if (query) {
        // Convert to encoded string and prepend with ?
        queryString = new URLSearchParams(query).toString();
        queryString = queryString && `?${queryString}`;
    }

    return new Promise((resolve, reject) => {
        fetchWithTimeout(`${url}${queryString}`, reqOptions, timeout)
            .then((res) => resolve(parseResponse(res)))
            .catch((error) => reject(error))
    });
}

export function request(url, options = {}, timeout = REQUEST_TIMEOUT_MSEC) {
    const {
        headers,
        query = null,
        method = 'GET',
        body,
        ...extraOpts
    } = options;

    // Compose the request configuration object
    const reqOptions = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        ...extraOpts,
    };

    if(method !== 'GET'){
        reqOptions.body= {
            ...body,
        }
    }

    return new Promise((resolve, reject) => {
        simple_request(url, reqOptions, timeout).then(resolve).catch(reject);
    });
}

export function request_auth(url, options = {}, timeout = REQUEST_TIMEOUT_MSEC) {
    const {
        headers,
        query = null,
        method = 'GET',
        body,
        ...extraOpts
    } = options;
    // Compose the request configuration object
    const reqOptions = {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: {
            token: getTokenHash(),
            ...body,
        },
        ...extraOpts,
    };

    return new Promise((resolve, reject) => {
        simple_request(url, reqOptions, timeout).then(resolve).catch(reject);
    });
}

// For uploading files
export function request_post_file(url, options = {}, timeout = REQUEST_TIMEOUT_MSEC) {
    const {
        headers,
        query = null,
        method = 'GET',
        formData,
        ...extraOpts
    } = options;

    const reqOptions = {
        method,
        headers,
        body: formData,
        ...extraOpts,
    };

    return new Promise((resolve, reject) => {
        simple_request(url, reqOptions, timeout, true).then(resolve).catch(reject);
    });
}