export function LocalStorageGetItem(key, initialValue) {
    const item = window.localStorage.getItem(key);
    if (item === null || item === undefined)
        return initialValue
    if (JSON.parse(item).expiry > 0 && JSON.parse(item).expiry < new Date().getTime()) {
        LocalStorageRemoveItem(key)
        return initialValue
    }
    return JSON.parse(item).data // same as item ? item : initialValue;
}

export function LocalStorageSetItem(key, value, expiry_in_ms = 0) {
    try {
        let item = {
            "data": value,
            "expiry": expiry_in_ms > 0 ? new Date().getTime() + expiry_in_ms : 0,
        }
        window.localStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
        console.error(error);
    }
}

export function LocalStorageRemoveItem(key) {
    window.localStorage.removeItem(key);
}

export function LocalStorageClear() {
    window.localStorage.clear();
}
