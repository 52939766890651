import { LocalStorageGetItem, LocalStorageSetItem, LocalStorageRemoveItem, LocalStorageClear } from './LocalStorage';

export function getTokenHash() {
    return LocalStorageGetItem("token", "");

}

export function setTokenHash(token) {
    return LocalStorageSetItem("token", token);
}

export function deleteTokenData(token) {
    LocalStorageClear();
    return LocalStorageRemoveItem("token");
}

export function getValueFromSet(key, set, default_value) {
    return (!(key in set) || set[key] === undefined || set[key] === null) ? default_value : set[key];
}

export function getQueryVariable(variable, default_value) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    let i = 0;
    let pair = [];
    for (i = 0; i < vars.length; i++) {
        pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return default_value;
}

export function getQueryVariableAsDict() {
    const query = window.location.search.substring(1);
    if (query === '')
        return null;
    let dict = {};
    const vars = query.split('&');
    let pair = [];
    for (let i = 0; i < vars.length; i++) {
        pair = vars[i].split('=');
        dict[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return dict;
}