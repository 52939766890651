import React, { Component } from 'react'
import * as general_api from "./api/general_api"

const UserContext = React.createContext()

class UserProvider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {
        is_admin: false,
        is_seller: false,
      },
    }
    general_api.verify().then((is_admin) => {
      this.setUser( {
          "is_admin": is_admin,
          "is_seller": !is_admin,
      })
    }).finally(()=>{
      props.onDone(true);
    })
  }

  setUser = (user) => {
    this.setState((prevState) => ({ user }))
  }

  render() {
    const { children } = this.props
    const { user } = this.state
    const { setUser } = this
    return (
      <UserContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export { UserProvider }