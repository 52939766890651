export const REQUEST_TIMEOUT_MSEC = 50000

// General API
export const LOGIN_URL = 'api/v1/auth/login';
export const VERIFY_URL = 'api/v1/auth/verify';
export const SEARCH_PROPERTIES_URL = 'api/v1/property/search';
export const GET_PROPERTY_URL = 'api/v1/property-details';
export const SAVE_LEAD_URL = 'api/v1/contact_me';

// Admin API
export const CUSTOMERS_URL = 'api/v1/admin/customer/fetch-all';
export const ADD_CUSTOMERS_URL = 'api/v1/admin/customer/new';
export const GET_CUSTOMERS_URL = 'api/v1/admin/customer/fetch';
export const UPDATE_CUSTOMERS_URL = 'api/v1/admin/customer/update';
export const PREFERENCES_CUSTOMERS_URL = 'api/v1/admin/customer/fetch-preferences';
export const SELLERS_URL = 'api/v1/admin/users/fetch-sellers';
export const GET_USER_URL = 'api/v1/admin/users/fetch';
export const UPDATE_USER_URL = 'api/v1/admin/users/update';
export const UPDATE_PASSWORD_USER_URL = 'api/v1/admin/users/update-password';
export const GET_USER_PROPERTIES_URL = 'api/v1/admin/property/user';
export const UPDATE_LEAD_API = 'api/v1/admin/leads/update';

export const GET_LEADS_URL = 'api/v1/admin/leads/fetch-all';

// Seller API
export const SELLER_GET_ME = 'api/v1/seller/details';
export const  SELLER_UPDATE_INFO = 'api/v1/seller/update';
export const SELLER_UPDATE_PASSWORD = 'api/v1/seller/update-password';
export const SELLER_GET_PROPERTIES_URL = 'api/v1/seller/properties';
export const SELLER_GET_PROPERTY_URL = 'api/v1/seller/property-details';

export const DEFAULT_PHONE = '050-4630063';
