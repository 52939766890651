import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserProvider } from './UserContext'
import HistoryProvider from "./components/Dialogs/History"
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
// const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
// const Homethree = React.lazy(() => import("./components/pages/Homethree"));
// const Homefour = React.lazy(() => import("./components/pages/Homefour"));
// const Homefive = React.lazy(() => import("./components/pages/Homefive"));
// Blog
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogsingle = React.lazy(() => import("./components/pages/Blogsingle"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Services = React.lazy(() => import("./components/pages/Services"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Pricing = React.lazy(() => import("./components/pages/Pricing"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));
const Register = React.lazy(() => import("./components/pages/Register"));
const Legal = React.lazy(() => import("./components/pages/Legal"));
// Listings
const Listinggrid = React.lazy(() => import("./components/pages/Listinggrid"));
// const Listinglist = React.lazy(() => import("./components/pages/Listinglist"));
// const Listingmap = React.lazy(() => import("./components/pages/Listingmap"));
const Listingdetailsone = React.lazy(() => import("./components/pages/Listingdetailsone"));
const Listingdetailstwo = React.lazy(() => import("./components/pages/Listingdetailstwo"));
const Listingdetailsthree = React.lazy(() => import("./components/pages/Listingdetailsthree"));
const Submitlisting = React.lazy(() => import("./components/pages/Submitlisting"));
const Comparelistings = React.lazy(() => import("./components/pages/Comparelistings"));
// Admin
const Customers = React.lazy(() => import("./components/pages/admin/Customers"));
const CustomerSubmit = React.lazy(() => import("./components/pages/admin/CustomerSubmit"));
const CustomerPage = React.lazy(() => import("./components/pages/admin/Customer"));
const Property = React.lazy(() => import("./components/pages/admin/Property"));
const Sellers = React.lazy(() => import("./components/pages/admin/Sellers"));
const Leads = React.lazy(() => import("./components/pages/admin/Leads"));
const Properties = React.lazy(() => import("./components/pages/admin/Properties"));
const Abounded = React.lazy(() => import("./components/pages/admin/Abounded"));
const Calculator = React.lazy(() => import("./components/pages/Calculator"));
// const Agents = React.lazy(() => import("./components/pages/admin/Agents"));
// const AddCustomer = React.lazy(() => import("./components/pages/admin/AddCustomer"));
// const AddProperty = React.lazy(() => import("./components/pages/admin/AddProperty"));
// const AddAgent = React.lazy(() => import("./components/pages/admin/AddAgent"));

//Seller
const ProfileSeller = React.lazy(() => import("./components/pages/seller/ProfileSeller"));
const ProfileSellerListings = React.lazy(() => import("./components/pages/seller/ProfileSellerListings"));

// Agents
// const Agentarchive = React.lazy(() => import("./components/pages/Agentarchive"));
// const Agentdetails = React.lazy(() => import("./components/pages/Agentdetails"));
// Agency
// const Agencyarchive = React.lazy(() => import("./components/pages/Agencyarchive"));
// const Agencydetails = React.lazy(() => import("./components/pages/Agencydetails"));



function App() {
  const [isReady, setIsReady] = useState(false);


  return (
    <UserProvider onDone={setIsReady}>
      <HistoryProvider>
        <Router>
          <Suspense fallback={<div></div>}>
            {!isReady ? <Preloader /> :
              <Switch>

                {/* Homepages */}
                <Route exact path="/" component={Home} />
                {/* <Route path="/home-v2" component={Hometwo} />
            <Route path="/home-v3" component={Homethree} />
            <Route path="/home-v4" component={Homefour} />
            <Route path="/home-v5" component={Homefive} /> */}
                {/* Blog */}
                <Route path="/blog" component={Bloggrid} />
                <Route path="/post" component={Blogsingle} />
                {/* Pages */}
                <Route path="/about" component={About} />
                <Route path="/services" component={Services} />
                <Route path="/faq" component={Faq} />
                <Route path="/pricing" component={Pricing} />
                <Route path="/contact" component={Contact} />
                <Route path="/coming-soon" component={Comingsoon} />
                <Route path="/error-404" component={Error} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                {/* <Route path="/legal" component={Legal} /> */}
                <Route path="/calculator" component={Calculator} />
                {/* Listings */}
                <Route path="/listing" component={Listinggrid} />
                {/* <Route path="/listing-list" component={Listinglist} /> */}
                {/* <Route path="/listing-map" component={Listingmap} /> */}
                <Route path="/listing-details" component={Listingdetailsone} />
                <Route path="/listing-details-v2" component={Listingdetailstwo} />
                <Route path="/listing-details-v3" component={Listingdetailsthree} />
                <Route path="/submit-listing" component={Submitlisting} />
                <Route path="/compare-listings" component={Comparelistings} />
                {/* Admin */}
                <Route path="/customers" component={Customers} />
                <Route path="/add-customer" component={CustomerSubmit} />
                <Route path="/edit-customer" component={CustomerSubmit} />
                <Route path="/customer" component={CustomerPage} />
                <Route path="/property" component={Property} />
                <Route path="/sellers" component={Sellers} />
                <Route path="/profile-seller" component={ProfileSeller} />
                <Route path="/profile-seller-listings" component={ProfileSellerListings} />
                <Route path="/leads" component={Leads} />
                <Route path="/properties" component={Properties} />
                <Route path="/abounded" component={Abounded} />
                {/* <Route path="/properties" component={Properties} />
            <Route path="/agents" component={Agents} />
            <Route path="/add-customer" component={AddCustomer} />
            <Route path="/add-property" component={AddProperty} />
            <Route path="/add-agent" component={AddAgent} /> */}
                {/* Sellers */}
                <Route path="/me/profile" component={ProfileSeller} />
                <Route path="/me/profile-listings" component={ProfileSellerListings} />
                {/* Agents */}
                {/* <Route path="/agent-archive" component={Agentarchive} />
            <Route path="/agent-details" component={Agentdetails} /> */}
                {/* <Route path="/profile" component={Profile} />
            <Route path="/profile-listings" component={Profilelistings} />
            <Route path="/profile-saved-listings" component={Profilesavedlistings} /> */}
                {/* Agency */}
                {/* <Route path="/agency-archive" component={Agencyarchive} />
            <Route path="/agency-details" component={Agencydetails} /> */}
              </Switch>}
          </Suspense>
        </Router>
      </HistoryProvider>
    </UserProvider>
  );
}

export default App;
