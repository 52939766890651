import { request } from "./RequestHelper"
import { environment } from '../conf';
import {
    LOGIN_URL, VERIFY_URL,
    SEARCH_PROPERTIES_URL,
    GET_PROPERTY_URL,
    SAVE_LEAD_URL,
} from '../Constants';
import { getValueFromSet, setTokenHash, getTokenHash } from "../services"

function serialize(obj) {
    var str = [];
    for (var p in obj)
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
}

export function verify() {
    const options = {
        method: 'POST',
        body: {
            token: getTokenHash(),
        },
    };
    return new Promise((resolve, reject) => {
        request(environment.base_url + VERIFY_URL, options)
            .then((data) => {
                if (data["status"]) {
                    resolve(data["data"]["is_admin"]);
                } else {
                    reject("VERFITY_ERROR")
                }
            }).catch((error) => {
                reject(error);
            });
    });
}

export function login(username, password) {
    const options = {
        method: 'POST',
        body: {
            username,
            password,
        },
    };
    return new Promise((resolve, reject) => {
        request(environment.base_url + LOGIN_URL, options)
            .then((data) => {

                if (data['status']) {
                    let token = getValueFromSet("token", data["data"], "");
                    let is_admin = getValueFromSet("is_admin", data["data"], false);
                    setTokenHash(token);
                    resolve({
                        "is_admin": is_admin,
                    });
                } else {
                    reject("VERFITY_ERROR");
                }
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export function register(name, email, phone, password) {
    const options = {
        method: 'POST',
        body: {
            name,
            email,
            phone,
            password,
        },
    };
    return new Promise((resolve, reject) => {
        request(environment.base_url + "api/v1/auth/register", options)
            .then((data) => {
                if (data['status']) {
                    let token = getValueFromSet("token", data["data"], "");
                    let is_admin = getValueFromSet("is_admin", data["data"], false);
                    setTokenHash(token);
                    resolve({
                        "is_admin": is_admin,
                    });
                } else {
                    reject(data["message"]);
                }
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export function SearchPropertiesQuery(query_param) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'GET'
        }
        request(environment.base_url + SEARCH_PROPERTIES_URL + "?" + serialize(query_param), options)
            .then((data) => {
                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function GetProperty(property_id) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                property_id: property_id
            }
        }
        request(environment.base_url + GET_PROPERTY_URL, options)
            .then((data) => {

                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function SendLead(name, email, phone, message="", type="כללי", property_id = null) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                property_id,
                name,
                email,
                phone,
                message,
                type,
            }
        }
        request(environment.base_url + SAVE_LEAD_URL, options)
            .then((data) => {
                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function CreateSellerNew(name, phone, property_type) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                name,
                phone,
                property_type
            }
        }
        request(environment.base_url + "/api/v1/start-property", options)
            .then((data) => {

                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function UpdateProperty(property) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                property: property,
            }
        }
        request(environment.base_url + "/api/v1/update-property", options)
            .then((data) => {

                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function CreateNewPropertyForUser(property) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                property: property,
            }
        }
        request(environment.base_url + "/api/v1/user/new-property", options)
            .then((data) => {

                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function PublishNewProperty(property) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                property: property,
            }
        }
        request(environment.base_url + "/api/v1/publish-property", options)
            .then((data) => {

                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function PublishPropertyForUser(property) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                property: property,
            }
        }
        request(environment.base_url + "/api/v1/user/publish-property", options)
            .then((data) => {

                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export function ForgetPassword(username) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                username,
            }
        }
        request(environment.base_url + "/api/v1/auth/forget-password", options)
            .then((data) => {

                if (data["status"]) {
                    resolve(data["data"]);
                } else {
                    reject(data["message"])
                }
            }).catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}
