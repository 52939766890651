const REACT_APP_STAGE="prod"

const debug = {
    terminal_name: "nadlan",
    response_language: "english",
    base_url: "http://localhost:5000/"
};

const dev = {
    terminal_name: "nadlan",
    response_language: "english",
    base_url: "https://api.fair4share.com/"
};

const prod = {
    terminal_name: "nadlan",
    response_language: "english",
    base_url: "https://api.fair4share.com/"
};



export const environment = (() => {
    switch (REACT_APP_STAGE) {
        case "debug":
            return debug;
        case "dev":
            return dev;
        case "prod":
            return prod;
        default:
            return null;
    }
})();